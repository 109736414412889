import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
import Sections from 'components/Section';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=BrandedHeading wrap=flex",
        "componentName": "BrandedHeading",
        "wrap": "flex"
      }}>{`<BrandedHeading>A header that contains built-in branding</BrandedHeading>
`}</code></pre>
    <Section brands={["wl", "dl", "ds", "hbvl", "nb"]} mdxType="Section">
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` While this component is supposed to contain in-depth branding, it is only a placeholder for this brand. Further branding remains to be explored with the currently selected brand.`}</p>
      </blockquote>
    </Section>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="BrandedHeading" mdxType="Props" />
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Link to a different page, or trigger states via action. You can render any element you like here.`}</p>
    <Section exclude={["swcom"]} mdxType="Section">
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`<BrandedHeading action={<Button onClick={() => alert('Hello world')} appearance="plain" size="small">Action</Button>}>Hello world</BrandedHeading>
`}</code></pre>
    </Section>
    <Section brands={["swcom"]} mdxType="Section">
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`<BrandedHeading action={<a href="#">Action</a>}>Hello world</BrandedHeading>
`}</code></pre>
    </Section>
    <h2 {...{
      "id": "appearance",
      "style": {
        "position": "relative"
      }
    }}>{`Appearance`}</h2>
    <p><inlineCode parentName="p">{`default: primary`}</inlineCode></p>
    <p>{`There are 2 possible appearances a BrandedHeading can have. Secondary is in most cases used on dark backgrounds.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box py={8} px={4}>
  <BrandedHeading appearance="primary">Primary</BrandedHeading>
</Box>
<Box py={8} px={4} bgColor="grey90">
  <BrandedHeading appearance="secondary">Secondary</BrandedHeading>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "as",
      "style": {
        "position": "relative"
      }
    }}>{`As`}</h2>
    <p><inlineCode parentName="p">{`default: h1`}</inlineCode></p>
    <p>{`Render the component with a different HTML element to make sure it's semantically correct.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<BrandedHeading as="h1">H1</BrandedHeading>
<BrandedHeading as="h2">H2</BrandedHeading>
<BrandedHeading as="h3">H3</BrandedHeading>
`}</code></pre>
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p><inlineCode parentName="p">{`default: large`}</inlineCode></p>
    <p>{`Each size can be used in it's own context.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<BrandedHeading>Default large</BrandedHeading>
<BrandedHeading size="large">Large</BrandedHeading>
<BrandedHeading size="small">Small</BrandedHeading>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      